<template>
    <v-app>
        <v-fade-transition mode="out-in">
            <router-view />
        </v-fade-transition>
        <Pie></Pie>
  </v-app>
</template>

<script>
import Pie from './components/Pie.vue';


export default {
    components: {
        Pie,
    },

    created(){
        this.verificarSesion();
    },

    methods: {
        verificarSesion(){
            if(this.$cookie.get("usuario") != null || this.$cookie.get("usuario") != undefined){
                let tmp = {
                    nombre: this.$cookie.get("nombre"),
                    tipo: this.$cookie.get("tipo"), 
                    usuario: this.$cookie.get("usuario"), 
                    id: this.$cookie.get("id"),
                    idSuperAdmin: null
                }
                if(this.$cookie.get("tipo") != "SuperAdmin"){
                    tmp.idSuperAdmin = this.$cookie.get("idSuperAdmin");
                }
                ////console.log(tmp);
                this.$store.commit('setUsuarios', tmp);
            }else{
                this.$store.commit('setUsuarios', {
                    nombre: null,
                    tipo: null,
                    usuario: null,
                    id: null,
                    idSuperAdmin: null
                });
            }
            this.$store.commit("guardarApiKey", this.$cookie.get('gdfhjk'));
        }
    }
}
</script>

<style>
</style>