// https://vuex.vuejs.org/en/mutations.html

export default {
  setUsuarios: (state, valores) => {
    ////console.log(valores.nombre);
    state.usuario.nombre = valores.nombre;
    state.usuario.tipo = valores.tipo;
    state.usuario.usuario = valores.usuario;
    state.usuario.id = valores.id;
    state.usuario.idSuperAdmin = valores.idSuperAdmin;

  },

  setNombreUsuario: (state, nuevoNombre) => {
    state.usuario.nombre = nuevoNombre;
  },

  setSesionActiva: (state, nuevosValores) => {
    state.sesionActiva = nuevosValores;
  },

  setVistaActual_adminf: (state, nuevosValores) => {
    state.vistaActual_adminf = nuevosValores;
  },

  setBusqueda: (state, nuevoValor) => {
    state.busqueda = nuevoValor;
  },

  guardarApiKey: (state, nuevoValor) => {
    state.apiKey = nuevoValor;
  },

  setCentroMapa: (state, nuevosValores) => {
    state.centroMapa = nuevosValores;
  },

  setPuntoBase: (state, nuevosValores) => {
    state.puntoBase = nuevosValores;
  },

  setCBZonas: (state, nuevosValores) => {
    state.cbZonas = nuevosValores;
  },

  setListaPredios: (state, valor) => {
    state.listaPredios = valor;
  },

  setListaLotes: (state, valor) => {
    state.listaLotes = valor;
  },

  setListaTequilas: (state, valor) => {
    state.listaTequilas = valor;
  },

  setListaFabricas: (state, valor) => {
    state.listaFabricas = valor;
  },

  setListaNOMs: (state, valor) => {
    state.listaNOMs = valor;
  },

  setListaBarricasProc: (state, valor) => {
    state.listaBarricasProc = valor;
  },

  addElementoListaBarricasProc: (state, valor) => {
    state.listaBarricasProc.push(valor);
  },

  setListaEmbotellados: (state, valor) => {
    state.listaEmbotellados = valor;
  },

  addElementoPredioLote: (state, valor) => {
    state.datosFormaLote.predios.push(valor);
  },
  
  quitarElementoPredioLote: (state, valor) => {
    state.datosFormaLote.predios.splice(valor, 1);
  },

  cambiarOpcionesAdmin: (state, activar) => {
    state.links[7].mostrar = activar;
    state.links[8].mostrar = activar;
  },
  limpiarInfoUsuario: state => {
    state.usuario.nombre = null;
    state.usuario.rol = null;
  },
  setDatosPredios: (state, nuevosValores) => {
    state.datosPredios = [...nuevosValores];
  },
  setDatosTequilas: (state, nuevosValores) => {
    state.datosTequilas = [...nuevosValores];
  },
  setDatosZonas: (state, nuevosValores) => {
    state.datosZonas = [...nuevosValores];
  },
  setDatosLotes: (state, nuevosValores) => {
    state.datosLotes = [...nuevosValores];
  },
  setDatosBarricas: (state, nuevosValores) => {
    state.datosBarricas = [...nuevosValores];
  },
  setDatosEmbotellados: (state, nuevosValores) => {
    state.datosEmbotellados = [...nuevosValores];
  },
  setDatosUsuarios: (state, nuevosValores) => {
    state.datosUsuarios = [...nuevosValores];
  },
  setDatosQRs: (state, nuevosValores) => {
    state.datosQRs = [...nuevosValores];
  },
  setDatosFabrica: (state, nuevosValores) => {
    state.datosFabricas = [...nuevosValores];
  },
  
  //Forma Usuario
  setDatosFormaUsuario: (state, valor) => {
    state.datosFormaUsuario = {...valor};
  },
  setDatosFormaCampoUsuario: (state, valor) => {
    state.datosFormaUsuario[valor[0]] = valor[1];
  },

  //Forma Tequila
  setDatosFormaTequila: (state, valor) => {
    state.datosFormaTequila = {...valor};
  },
  setDatosFormaCampoTequila: (state, valor) => {
    state.datosFormaTequila[valor[0]] = valor[1];
  },

  //Forma Zona
  setDatosFormaZona: (state, valor) => {
    state.datosFormaZona = {...valor};
  },
  setDatosFormaCampoZona: (state, valor) => {
    state.datosFormaZona[valor[0]] = valor[1];
  },

  //Forma Predio
  setDatosFormaPredio: (state, valor) => {
    state.datosFormaPredios = {...valor};
  },
  setDatosFormaCampoPredio: (state, valor) => {
    state.datosFormaPredios[valor[0]] = valor[1];
  },

  //Forma Lote
  setDatosFormaLote: (state, valor) => {
    state.datosFormaLote = {...valor};
  },
  setDatosFormaCampoLote: (state, valor) => {
    state.datosFormaLote[valor[0]] = valor[1];
  },

  //Forma Barrica
  setDatosFormaBarrica: (state, valor) => {
    state.datosFormaBarrica = {...valor};
  },
  setDatosFormaCampoBarrica: (state, valor) => {
    state.datosFormaBarrica[valor[0]] = valor[1];
  },
  addTablaLote: (state, valor) => {
    state.datosFormaBarrica["lotes"].push(valor);
  },
  removeTablaLote: (state, valor) => {
    const indice = state.datosFormaBarrica["lotes"].indexOf(valor);
    if (indice > -1) {
      state.datosFormaBarrica["lotes"].splice(indice, 1); 
    }
  },

  //Forma embotellado
  setDatosFormaEmbotellado: (state, valor) => {
    state.datosFormaEmbotellado = {...valor};
  },
  setDatosFormaCampoEmbotellado: (state, valor) => {
    state.datosFormaEmbotellado[valor[0]] = valor[1];
  },
  addTablaEnvasado: (state, valor) => {
    state.datosFormaEmbotellado["envasados"].push(valor);
  },

  setTablaEnvasado: (state, valor) => {
    state.datosFormaEmbotellado["envasados"] = [...valor];
  },

  removeTablaEnvasado: (state, valor) => {
    const indice = state.datosFormaEmbotellado["envasados"].indexOf(valor);
    if (indice > -1) {
      state.datosFormaEmbotellado["envasados"].splice(indice, 1); 
    }
  },

  //Forma QR
  setDatosFormaQR: (state, valor) => {
    state.datosFormaQR = {...valor};
  },
  setDatosFormaCampoQR: (state, valor) => {
    state.datosFormaQR[valor[0]] = valor[1];
  },
  setInformacionQR: (state, valor) => {
    state.informacionQR = {...valor};
  },
  setImagenEnvasadoInformacionQR: (state, valor) => {
    state.informacionQR.envasados[valor[0]].imagen = valor[1];
  },


  setEstadoTarjetaInfo: (state, valor) => {
    state.estado_tarjeta_info = valor;
  },

  //Forma de Perfil Datos generales
  setDatosFormaDatosGenerales: (state, valor) => {
    state.datosFormaDatosGenerales = {...valor};
  },

  setDatosFormaCampoDatosGenerales: (state, valor) => {
    state.datosFormaDatosGenerales[valor[0]] = valor[1];
  },

  //Forma de Perfil Datos generales
  setDatosFormaEmpresa: (state, valor) => {
    state.datosFormaEmpresa = {...valor};
  },

  setDatosFormaCampoEmpresa: (state, valor) => {
    state.datosFormaEmpresa[valor[0]] = valor[1];
  },

  //Forma de Perfil Fabrica
  setDatosFormaFabrica: (state, valor) => {
    state.datosFormaFabricas = {...valor};
  },

  setDatosFormaCampoFabrica: (state, valor) => {
    state.datosFormaFabricas[valor[0]] = valor[1];
  },

  //Actualizar tabla NOM
  setDatosNOM: (state, valor) => {
    state.datosNOM = [...valor];
  },
  
  //Añadir a tabla NOM
  addDatosNOM: (state, valor) => {
    state.datosNOM.push(valor);
  },

  //Quitar elemento de tabla NOM
  removeDatosNOM: (state, valor) => {
    const indice = state.datosNOM.indexOf(valor);
    if (indice > -1) {
      state.datosNOM.splice(indice, 1); 
    }
  },

  //VTEQUILA
  setTequilaSeleccionado: (state, valor) => {
    state.asistente.tequilaSeleccionado = {...valor};
  },

  setImagenTequilaSeleccionado: (state, valor) => {
    state.asistente.tequilaSeleccionado.imagen = valor;
  },

  setIDTequilaInsertado: (state, valor) => {
    state.ultimoIDTequilaInsertado = valor;
  },

  setNOMSeleccionado: (state, valor) => {
    state.asistente.nomSeleccionado = {...valor};
  },

  setFormaNOM: (state, valor) => {
    state.formaNOM = valor;
  },

  setIDNOMInsertado: (state, valor) => {
    state.ultimoIDNOMInsertado = valor;
  },

  setFabricaSeleccionada: (state, valor) => {
    state.asistente.fabricaSeleccionada = {...valor};
  },
  
  setIDFabricaInsertado: (state, valor) => {
    state.ultimoIDFabricaInsertado = valor;
  },

  setIDBarricaInsertada: (state, valor) => {
    state.ultimoIDBarricaInsertada = valor;
  },
  
  setIDEmbotelladoInsertado: (state, valor) => {
    state.ultimoIDEmbotelladoInsertado = valor;
  },

  setPrediosSeleccionados: (state, valor) => {
    state.asistente.prediosSeleccionados = [...valor];
  },

  setLotesSeleccionados: (state, valor) => {
    state.asistente.lotesSeleccionados = [...valor];
  },

  setBarricaSeleccionada: (state, valor) => {
    state.asistente.barricaSeleccionada = {...valor};
  },

  setEmbotelladoSeleccionado: (state, valor) => {
    state.asistente.embotelladoSeleccionado = {...valor};
  },

  setTipoTequila: (state, valor) => {
    state.tipoTequila = valor;
  },

  setCapacidad: (state, valor) => {
    state.capacidad = valor;
  },

  setEstadoBotonSiguienteAsistente: (state, valor) => {
    state.botonSiguienteAsistente = valor;
  },

  setDatosEnvasado: (state, valor) => {
    state.asistente.envasado[valor[0]] = valor[1];
  },

  setEstadoBarrica: (state, valor) => {
    state.asistente.estado_barrica = valor;
  },

  setNombreQR: (state, valor) => {
    state.asistente.nombreQR = valor;
  },

  setAsistente: (state, valor) => {
    state.asistente = valor;
  },

  setListaLotesBlanco: (state, valor) => {
    state.asistente.listaLotesBlanco = [...valor];
  },

  setTituloEnvasado: (state, valor) => {
    state.asistente.tituloEnvasado = valor;
  }
}