/**
 * Definicion general de rutas de la aplicación
 */
export default [
    {
        path: '/inicio',
        name: 'Página de inicio. Seleccion de opcion',
        view: 'Inicio',
        meta: { requiresAuth: true },
    },
    {
        path: '/login',
        name: 'Iniciar sesión',
        view: 'Login',
        meta: { requiresAuth: false },
    },
    {
        path: '/adminf',
        name: 'Administración de la información',
        view: 'AdminInfo',
        meta: { requiresAuth: true },
    },
    {
        path: '/qrs',
        name: 'Creación y visualización de QRs',
        view: 'Dashboard',
        meta: { requiresAuth: true },
    },
    /*{
        path: '/predios',
        name: 'Predios',
        view: 'Predios'
    },*/
    /*{
        path: '/opciones',
        name: 'Configuracion',
        view: 'Opciones'
    },*/
    /*{
        path: '/barricas',
        name: 'Barricas',
        view: 'Barricas'
    },
    {
        path: '/tequilas',
        name: 'Tequilas',
        view: 'Tequilas'
    },
    {
        path: '/embotellado',
        name: 'Embotellado',
        view: 'Embotellado'
    },
    {
        path: '/lotes',
        name: 'Lotes',
        view: 'Lotes'
    },
    {
        path: '/usuarios',
        name: 'Usuarios',
        view: 'Usuarios'
    },
    {
        path: '/login',
        name: 'Iniciar Sesion',
        view: 'Login'
    },*/
    {
        path: '/informacion/:usuario/:cadena',
        name: 'Información de la botella',
        view: 'Informacion',
        meta: { requiresAuth: false },
    },
    {
        path: '/perfil',
        name: 'Perfil de usuario',
        view: 'Perfil',
        meta: { requiresAuth: true },
    },
    /*{
        path: '/dashboard',
        name: 'Dashboard',
        view: 'Dashboard'
    },
    {
        path: '/404',
        name: 'Pagina no existente',
        view: 'NA'
    },*/
]