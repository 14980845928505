// https://vuex.vuejs.org/en/state.html

//const api_root_dir = "http://localhost:5001/api/t7l/";
//const logos_img_dir = `${api_root_dir}`;

//const ip_front = 'localhost';
//const ip_front = '192.168.100.79'; //LOCAL
const ip_front = '157.245.242.214'; //LOCAL
//const puerto_front = '8080';

const puerto_back = '5001';
//const ip_back = 'localhost'
//const ip_back = '10.40.60.130'; //Compu MAC CDE LAN TEC
const ip_back = '157.245.242.214'; //DIGITALOCEAN
//const ip_back = '192.168.0.102'; //SERVER TEC

export default {
    apiKey: null,
    geocoding_api_key: "AIzaSyDwtC4C8MGmiA4F0dcqWRRnp9Z6Uxac1L0",
    tipoMapa: "satellite",
    sesionActiva: false,
    api: `http://${ip_back}:${puerto_back}/api/t7l/`,
    
    //local: `http://${ip_front}:${puerto_front}`, //OTRAS CON PUERTO
    local: `http://${ip_front}`, //DIGITAL OCEAN
    
    //direccion_servidor: `http://${ip_front}:${puerto_front}/informacion/`, //CUALQUIERA CON PUERTO
    direccion_servidor: `http://${ip_front}/informacion/`, //DIGITAL OCEAN
    
    usuario: {
        usuario: null,
        nombre: null,
        tipo: null,
        id: null,
        idSuperAdmin: null,
    },

    busqueda: "",
    frase_cifrar: "t7lqrgPf",
    centroMapa: { lat: 20.550588056146232, lng: -102.51073885775286 },
    puntoBase: { lat: 20.550588056146232, lng: -102.51073885775286 },
    vistaActual_adminf: "Tequilas",

    tipoTequila: "",
    capacidad: 0,

    informacionQR: {
        nombre_barrica: "",
        descripcion_barrica: "",
        empresa: {
            nombre: "",
            direccion: {
                coordenadas: { lat: 20.550588056146232, lng: -102.51073885775286 },
                direccion: "",
                id_lugar: ""
            },
            descripcion: "",
        },
        envasados: [
          /*{
            id_tequila: 18,
            num_botellas: "655665",
            id_nom: 2,
            fecha_envasado: "2023-04-12",
            marbete: "hjn",
            nom: "FDJKL342234",
            nombre_tequila: "7 Leguas",
            clase_tequila: "Blanco",
            categoria_tequila: "100% agave azul"
          },*/
        ],
        lotes: [
          /*{
                nombre_lote: "Lote 1",
                descripcion: "descripcion de lote 1",
                predios: [
                    {
                        nombre_predio: "San Juanico",
                        descripcion_predio: "Aqui va la descripcion de San Juanico.",
                        nombre_zona: "Altos",
                        latitud: 20.551973441323145,
                        longitud: -102.50709637747008
                    }
                ]
            }*/
        ]
    },
    estado_tarjeta_info: false,
    publicPath: process.env.BASE_URL,

    redes_sociales: [
        {icono: 'mdi-facebook', liga: 'https://facebook.com'},
        {icono: 'mdi-twitter', liga: 'https://twitter.com'},
        {icono: 'mdi-linkedin', liga: 'https://linkedin.com'},
        {icono: 'mdi-instagram', liga: 'https://instagram.com'}
    ],

    ultimoIDTequilaInsertado: 0,
    ultimoIDNOMInsertado: 0,
    ultimoIDFabricaInsertado: 0,
    ultimoIDBarricaInsertada: 0,
    ultimoIDEmbotelladoInsertado: 0,

    botonSiguienteAsistente: true,

    asistente: {
        tequilaSeleccionado: {
            /*id: 0,
            nombre: "",
            clase: "",
            categoria: "",
            contenido: "",
            imagen: null*/
        },
        nomSeleccionado: {
            /*id: 0,
            nom: "",*/
        },
        fabricaSeleccionada: {
            /*id: 0,
            nombre: "",
            direccion: {
                direccion: "",
            },
            descripcion: "",
            id_nom: 0,
            nom: "",
            id_usuario: 1*/
        },
        prediosSeleccionados: [],
        lotesSeleccionados: [],
        barricaSeleccionada: {},
        embotelladoSeleccionado: {},
        tituloEnvasado: "",
        envasado: {
            id_tequila: 0,
            num_botellas: 0,
            id_nom: 0,
            fecha_envasado: new Date().toISOString().substr(0, 10),
            marbete: ""
        },
        estado_barrica: false,
        nombreQR: "",
        listaLotesBlanco: [
            /*{
                id_lote: 0,
                cantidad: 0    
            }*/
        ]
    },
    
    //cbZonas: [],
    listaPredios: [
        {text: "", value: ""}
    ],
    listaLotes: [
        {text: "", value: "", litros: 0}
    ],
    listaTequilas: [
        {text: "", value: ""}
    ],
    listaBarricasProc: [
        {text: "", value: ""}
    ],
    listaEmbotellados: [
        {text: "", value: ""}
    ],
    listaFabricas: [
        {text: "", value: ""}
    ],
    listaNOMs: [
        {text: "", value: ""}
    ],

    formaNOM: "",
    datosPredios: [],
    datosTequilas: [],
    datosZonas: [],
    datosLotes: [],
    datosBarricas: [],
    datosEmbotellados: [],
    datosUsuarios: [],
    datosQRs: [],
    datosFabricas: [],

    //Perfil 
    datosFormaDatosGenerales: {
        id: "",
        nombre: "",
        paterno: "",
        materno: "",
        usuario: "",
        password: "",
    },

    datosFormaEmpresa: {
        id: 0,
        nombre: "",
        direccion: "",
        descripcion: "",
        id_usuario: 0,
    },
    
    datosFormaUsuario: {
        id: 0,
        usuario: '',
        nombre: '',
        paterno: '',
        materno: '',
        password: '',
        password2: '',
        tipo: '',
        logo: null,
        nombre_empresa: '',
        direccion: {
            id_lugar: "",
            coordenadas: { lat: 20.550588056146232, lng: -102.51073885775286 },
            direccion: ""
        },
        descripcion: '',
    },

    datosFormaQR: {
        id: 0,
        id_embotellado: 0,
        nombre: "",
        direccion: "",
        id_usuario: 0
    },

    datosFormaTequila: {
        id: 0,
        nombre: "",
        clase: "",
        categoria: "",
        id_usuario: 0,
        contenido: 0,
        imagen: null
    },

    datosFormaZona: {
        id: 0,
        nombre: '',
    },

    datosFormaPredios: {
        id: 0,
        nombre: "",
        descripcion: "",
        direccion: {},
        zona: "",
        id_usuario: 0
    },

    datosFormaLote: {
        id: 0,
        nombre: '',
        descripcion: '',
        fechaini: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        fechaFin: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        id_predios: [],
        litros: 0,
        id_fabrica: 0,
        id_usuario: 0
    },

    datosFormaBarrica: {
        id: 0,
        nombre: '',
        descripcion: '',
        capacidad: 0,
        fecha_llenado: new Date().toISOString().substr(0, 10),
        lotes: [],
        tipo_tequila: 0,
        id_usuario: 0,
    },

    datosFormaEmbotellado: {
        id: 0,
        id_barrica: 0,
        nombre: "",
        envasados: [],
        id_usuario: 0,
        estado_barrica: false,
    },

    datosFormaFabricas: {
        id: 0,
        nombre: '',
        direccion: "",
        descripcion: '',
        id_nom: 0,
        id_usuario: 0,
    },

    datosNOM: [],

//-----------------------------------------
    color_botones: "green",
    color_formas: "cyan darken-4",
    formatoFecha: { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'},
    formatoFechaHora: { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: "2-digit", minute: "2-digit", hourCycle: "h12" },

    quitarPuntosDeInteres: [
        {
            featureType: "poi",
            stylers: [{visibility: "off"}]
        }
    ],

    opcionesAdd: [
        {
            funcion: "nuevoTequila()",
            icon: "mdi-bottle-tonic-plus",
            text: "Tequila"
        },
        {
            funcion: "nuevoPredio",
            icon: "mdi-map-plus",
            text: "Predio"
        },
        {
            funcion: "nuevoLote",
            icon: "mdi-home-map-marker",
            text: "Lote"
        },
        {
            funcion: "nuevaBarrica",
            icon: "mdi-plus-box-multiple",
            text: "Barrica"
        },
        {
            funcion: "nuevoEmbotellado",
            icon: "mdi-book-plus",
            text: "Embotellado"
        },
        {
            funcion: "nuevoQR",
            icon: "mdi-qrcode-plus",
            text: "QR"
        },

    ],

    opcionesUsuario: [
        {
            to : '/qrs',
            icon : 'mdi-qrcode',
            text : 'QRs',
            mostrar: true
        },
        {
            to : '/embotellado',
            icon : 'mdi-alpha-e-box',
            text : 'Embotellado',
            mostrar: true
        },
        {
            to : '/barricas',
            icon : 'mdi-barrel',
            text : 'Barricas',
            mostrar: true
        },
        {
            to : '/lotes',
            icon : 'mdi-zip-box',
            text : 'Lotes',
            mostrar: true
        },
        {
            to : '/predios',
            icon : 'mdi-map',
            text : 'Predios',
            mostrar: true
        },
        {
            to : '/tequilas',
            icon : 'mdi-bottle-wine',
            text : 'Tequilas',
            mostrar: true
        },
        {
            to : '/fabricas',
            icon : 'mdi-factory',
            text : 'Fabricas',
            mostrar: true
        },
    ],

    opcionesAdministrador: [
        {
            icon : 'mdi-account-group',
            text : 'Staff',
            mostrar: true
        },
        {
            icon : 'mdi-account-group',
            text : 'Staff',
            mostrar: true
        },
        {
            icon : 'mdi-account-group',
            text : 'Staff',
            mostrar: true
        },

    ],

    hPredios: [
        {
            text: "ID del predio",
            value: "id",
            sortable: false,
            align: ' d-none'
        },
        {
            text: 'Nombre del predio',
            value: 'nombre',
        },
        {
            text: 'Descripcion',
            value: 'descripcion',
        },
        {
            text: 'Direccion',
            value: 'direccion',
            sortable: false,
            align: ' d-none'
        },
        {
            text: 'Zona',
            value: 'zona',
        },
        {
            text: 'Mapa',
            value: 'mapa',
            sortable: false
        },
        {
            text: "ID Usuario",
            value: "id_usuario",
            sortable: false,
            align: ' d-none'
        },
        {
            text: 'Acciones',
            value: 'actions',
            sortable: false,
        },
    ],

    hPrediosAsistente: [
        {
            text: "ID del predio",
            value: "id",
            sortable: false,
            align: ' d-none'
        },
        {
            text: 'Nombre del predio',
            value: 'nombre',
        },
        {
            text: 'Descripcion',
            value: 'descripcion',
        },
        {
            text: 'Zona',
            value: 'zona',
        },
        {
            text: 'Mapa',
            value: 'mapa',
            sortable: false
        },
        {
            text: "ID Usuario",
            value: "id_usuario",
            sortable: false,
            align: ' d-none'
        },
        {
            text: "latitud",
            value: "latitud",
            sortable: false,
            align: ' d-none'
        },
        {
            text: "longitud",
            value: "longitud",
            sortable: false,
            align: ' d-none'
        },
    ],

    hTequilas: [
        {
            text: "ID de Tequila",
            value: "id",
            sortable: false,
            align: ' d-none'
        },
        {
            text: 'Nombre del tequila',
            value: 'nombre',
        },
        {
            text: 'Contenido',
            value: 'contenido',
        },
        {
            text: 'Clase',
            value: 'clase',
        },
        {
            text: 'Categoria',
            value: 'categoria',
        },
        {
            text: 'Imagen',
            value: 'imagen',
            sortable: false
        },
        {
            text: "ID de usuario",
            value: "id_usuario",
            sortable: false,
            align: ' d-none'
        },
        {
            text: 'Acciones',
            value: 'actions',
            sortable: false,
        },
    ],

    hLotes: [
        {
            text: "ID Lote",
            value: "id",
            sortable: false,
            align: ' d-none'
        },
        {
            text: "ID usuario",
            value: "id_usuario",
            sortable: false,
            align: ' d-none'
        },
        {
            text: "ID_Fabrica",
            value: "id_fabrica",
            sortable: false,
            align: ' d-none'
        },
        {
            text: 'Nombre del lote',
            value: 'nombre',
        },
        {
            text: 'Descripcion',
            value: 'descripcion',
        },
        {
            text: 'Litros disponibles',
            value: 'litros',
        },
        {
            text: 'Fabrica de procedencia',
            value: 'nombre_fabrica',
        },
        {
            text: 'Fecha de inicio de producción',
            value: 'fechaini',
        },
        {
            text: 'Fecha de cierre de producción',
            value: 'fechaFin',
        },
        {
            text: "Lotes",
            value: "lotes",
            sortable: false,
            align: ' d-none'
        },
        {
            text: 'Acciones',
            value: 'actions',
            sortable: false,
        },
    ],

    hLotesAsistente: [
        {
            text: "ID Lote",
            value: "id",
            sortable: false,
            align: ' d-none'
        },
        {
            text: "ID usuario",
            value: "id_usuario",
            sortable: false,
            align: ' d-none'
        },
        {
            text: "ID_Fabrica",
            value: "id_fabrica",
            sortable: false,
            align: ' d-none'
        },
        {
            text: 'Nombre del lote',
            value: 'nombre',
        },
        {
            text: 'Descripcion',
            value: 'descripcion',
        },
        {
            text: 'Litros disponibles',
            value: 'litros',
        },
        {
            text: 'Fabrica de procedencia',
            value: 'nombre_fabrica',
        },
        {
            text: 'Lista de predios',
            value: 'id_predios',
            sortable: false,
            align: ' d-none'
        },
        {
            text: 'Fecha de inicio de producción',
            value: 'fechaini',
        },
        {
            text: 'Fecha de cierre de producción',
            value: 'fechaFin',
        },
        {
            text: "Lotes",
            value: "lotes",
            sortable: false,
            align: ' d-none'
        },
    ],
    
    hBarricas: [
        {
            text: "ID",
            value: "id",
            sortable: false,
            align: ' d-none'
        },
        {
            text: 'Nombre de las barricas',
            value: 'nombre',
        },
        {
            text: 'Descripcion',
            value: 'descripcion',
        },
        {
            text: 'Capacidad',
            value: 'capacidad',
        },
        {
            text: 'Fecha de llenado',
            value: 'fecha_llenado',
        },
        {
            text: 'Tipo de tequila',
            value: 'tipo_tequila',
        },
        {
            text: "Lotes",
            value: "lotes",
            sortable: false,
            align: ' d-none'
        },
        {
            text: 'Acciones',
            value: 'actions',
            sortable: false,
        },
    ],

    hEmbotellados: [
        {
            text: "ID",
            value: "id",
            sortable: false,
            align: ' d-none'
        },
        {
            text: "id_barrica",
            value: "id_barrica",
            sortable: false,
            align: ' d-none'
        },
        {
            text: 'Nombre descriptivo del embotellado',
            value: 'nombre',
        },
        {
            text: 'Barrica de procedencia',
            value: 'nombre_barrica',
        },
        {
            text: 'Envasados',
            value: 'envasados',
            sortable: false,
            align: ' d-none'
        },
        {
            text: 'ID usuario',
            value: 'id_usuario',
            sortable: false,
            align: ' d-none'
        },
        {
            text: 'Estado barrica',
            value: 'estado_barrica',
            sortable: false,
            align: ' d-none'
        },
        {
            text: 'Acciones',
            value: 'actions',
            sortable: false,
        },
    ],

    hUsuarios: [
        {
            text: "ID interno",
            value: "id",
        },
        {
            text: 'Nombre(s)',
            value: 'nombre',
        },
        {
            text: 'Apellido Paterno',
            value: 'paterno',
        },
        {
            text: 'Apellido Materno',
            value: 'materno',
        },
        {
            text: 'Nombre de usuario',
            value: 'usuario',
        },
        {
            text: 'Tipo de usuario',
            value: 'tipo',
        },
        {
            text: 'Acciones',
            value: 'actions',
            sortable: false,
        },
    ],

    hQRs: [
        {
            text: "ID QR",
            value: "id",
        },
        {
            text: 'Titulo del QR',
            value: 'nombre',
        },
        {
            text: "ID embotellado",
            value: "id_embotellado",
            sortable: false,
            align: ' d-none'
        },
        {
            text: "nombre embotellado",
            value: "nombre_embotellado",
            sortable: false,
            align: ' d-none'
        },
        {
            text: "Embotellado",
            value: "embotellado",
        },
        {
            text: "Direccion",
            value: "direccion",
            sortable: false,
            align: ' d-none'
        },
        {
            text: 'Ver QR',
            value: 'qrcode',
            sortable: false
        },
        {
            text: 'Acciones',
            value: 'actions',
            sortable: false,
        },
    ],

    hFabricas: [
        {
            text: "ID de fábrica",
            value: "id",
            sortable: false,
            align: ' d-none'
        },
        {
            text: 'Nombre de la fabrica',
            value: 'nombre',
        },
        {
            text: 'Descripcion',
            value: 'descripcion',
        },
        {
            text: 'id_nom',
            value: 'id_nom',
            sortable: false,
            align: ' d-none'
        },
        {
            text: 'NOM',
            value: 'nom',
        },
        {
            text: 'Ubicación',
            value: 'direccion',
        },
        {
            text: "id_usuario",
            value: "id_usuario",
            sortable: false,
            align: ' d-none'
        },
        {
            text: 'Acciones',
            value: 'actions',
            sortable: false,
        },
    ],
}
