import Vue from 'vue'
import Router from 'vue-router'

//Lista de rutas en el proyecto
import paths from './paths'

/*
 * Automatiza el proceso de importación y obtención de elementos principales dentro del proyecto
 */
function route (path, view, name) {
    return {
        name: name || view,
        path,
        component: (resovle) => import(
            `@/views/${view}.vue`
        ).then(resovle)
    }
}

Vue.use(Router)

//Creacion del router y automatización del redireccionamiento
const router = new Router({
    mode: 'history',
    //routes: paths.map(path => route(path.path, path.view, path.name)),
    routes: paths.map(path => route(path.path, path.view, path.name, path.meta)).concat([
      { path: '*', redirect: '/login' }
    ]),
    scrollBehavior (to, from, savedPosition) {
      if (savedPosition) {
        return savedPosition
      }
      if (to.hash) {
        return { selector: to.hash }
      }
      return { x: 0, y: 0 }
    }
});

export default router