import Vue from 'vue';
import App from './App.vue';
import vuetify from './plugins/vuetify';
import store from './store';
import router from '@/router';
import * as VueGoogleMaps from 'vue2-google-maps';
import QrcodeVue from 'qrcode.vue';
import VueCryptojs from 'vue-cryptojs'

const VueCookie = require ('vue-cookie');
Vue.use(VueCookie);
Vue.use(VueCryptojs);

Vue.use(VueGoogleMaps, {
    load: {
        //key: 'AIzaSyC0dT9DfBzBAx1HkNiTElR8Sr9B58_PJaM',  //PERSONAL
        key: 'AIzaSyDwtC4C8MGmiA4F0dcqWRRnp9Z6Uxac1L0', //CIIOT
        libraries: 'places'
    }
})

function usuarioAutentificado(){
    if(VueCookie.get("usuario") != null || VueCookie.get("usuario") != undefined){
        store.commit('setUsuarios', {
            nombre: VueCookie.get("nombre"),
            tipo: VueCookie.get("tipo"), 
            usuario: VueCookie.get("usuario"), 
            id: VueCookie.get("id"),
            idSuperAdmin: VueCookie.get("idSuperAdmin")
        });
        return true;
    }else{
        store.commit('setUsuarios', {
            nombre: null,
            tipo: null,
            usuario: null,
            id: null,
            idSuperAdmin: null
        });
        return false;
    }
}

router.beforeEach((to, from, next) => {
    const isAuthenticated = usuarioAutentificado() // Lógica para verificar si el usuario está autenticado

    if (to.matched.some((route) => route.meta.requiresAuth)) {
        // La ruta requiere autenticación
        if (isAuthenticated) {
        // El usuario está autenticado, permitir la navegación
            next();
        } else {
        // El usuario no está autenticado, redirigir a la página de inicio de sesión
            next('/login');
        }
    } else {
    // La ruta no requiere autenticación, permitir la navegación
        next();
    }
});

Vue.component('qrcode-vue', QrcodeVue);

Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
